import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch } from "react-redux";
import {
  authUser,
  saveState,
  setPhone,
  setCode,
  setTagCode,
  setWhatsappStatus,
} from "../RegisterPetSlice";
import { authCustomer } from "../../../app/store/authSlice";
import { formatUserPhoneForDisplay } from "../../../utils/formatUserPhoneForDisplay";

const ConfirmCodeAPI = () => {
  const { http } = useAPITag();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const savePreRegister = async (data) => {
    try {
      return await http.patch("/tags/change-status/" + id, data);
    } catch (error) {
      console.log(error);
    }
  };

  const CheckCode = async (data) => {
    try {
      const response = await http.get("/user-token/auth-code/" + data.code);
      const user = response.data.user;
      const token = response.data.token;

      dispatch(setCode({ code: token.token }));
      dispatch(
        setTagCode({ tagCode: token.metadata?.originalTag || token.tagCode })
      );
      dispatch(
        setPhone({
          phone: token.userId,
          maskedPhone: formatUserPhoneForDisplay(
            `${token.countryCode}${token.userId}`
          ),
          grantType: token.tokenType,
          countryCode: token.countryCode,
        })
      );

      if (user) {
        dispatch(authUser(user));
        dispatch(authCustomer({ user, token: token }));
      }

      dispatch(setWhatsappStatus(true));
      dispatch(saveState());

      if (user) {
        const petsResponse = await http.get("pets/user/" + user._id);
        if (petsResponse.data.length > 0) {
          navigate("/select-pet");
        } else {
          navigate("/register-pet");
        }
      } else {
        navigate("/register-user");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Ocorreu um erro inesperado.";

      Swal.fire({
        icon: "error",
        title: "Erro",
        text: errorMessage,
      }).then(() => {
        navigate("/");
      });
    }
  };

  return { CheckCode, savePreRegister };
};

export default ConfirmCodeAPI;
