import { useEffect } from "react";
import { Spin } from "antd";
import ConfirmCodeAPI from "../confirm-code/ConfirmCodeAPI2";
import { useSelector } from "react-redux";
import { selectRegisterstate } from "../RegisterPetSlice";
import { useParams } from "react-router-dom";

export const FinishPage = () => {
  const { CheckCode } = ConfirmCodeAPI();
  const registerState = useSelector(selectRegisterstate);
  const { id: urlCode } = useParams();

  useEffect(() => {
    const codeToUse = registerState.code || urlCode;

    if (codeToUse) {
      CheckCode({
        code: codeToUse,
      });
    } else {
      console.error("Nenhum código de confirmação encontrado");
    }
  }, [CheckCode, registerState.code, urlCode]);

  return (
    <div className="flex items-center justify-center w-full h-screen">
      <Spin size="large" />
    </div>
  );
};
